import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.img`
  display: block;
  z-index: 3;
  width: 290px;
  height: auto;
  margin: 0 auto -80px auto;
  position: relative;

  ${media.moreThan('sm')`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
  `};

  ${media.moreThan('md')`
    width: 400px;
    top: 0;
    height: auto;
    right: -40px;
  `};

  ${media.moreThan('lg')`
    width: auto;
    right: 0;
    top: -20px;
  `};
`;
