import React from 'react';
import Box from '@sizeid/commons/components/Box';
import { Pictogram } from '@sizeid/commons/components/SvgImage';
import { nlBr } from '@sizeid/commons/utils/strings';
import { WithTranslation } from 'next-i18next';

import H3 from 'components/typo/Heading/H3';
import P from 'components/typo/P';
import { withTranslation } from 'lib/i18n';
import { Aos } from 'utils/animations';

import HowToItemWrap from './styled/HowToItemWrap';
import HowToItemInner from './styled/HowToItemInner';
import HowToItemTitle from './styled/HowToItemTitle';
import HowToItemInnerItem from './styled/HowToItemInnerItem';

const ITEMS = {
  '1': ['meter', 'click'],
  '2': ['add-person'],
  '3': ['shop', 'mobile'],
};

const HowToItem: React.FC<
  {
    step: string;
    mobileOnly?: boolean;
    aos?: Aos;
  } & WithTranslation
> = ({ step, t, mobileOnly, aos }) => {
  return (
    <HowToItemWrap {...aos} step={+step} mobileOnly={mobileOnly}>
      <HowToItemTitle>
        <Pictogram image={`label-${step}`} width="66px" height="80px" />
        {t(`home.how_to.${step}.title`)}
      </HowToItemTitle>

      <HowToItemInner step={+step}>
        {ITEMS[step as keyof typeof ITEMS].map(item => (
          <HowToItemInnerItem key={item}>
            <Pictogram image={item} size="88px" />

            <Box paddingLeft={1}>
              <H3>{nlBr(t(`home.how_to.${step}.items.${item}.title`))}</H3>
              <P>{nlBr(t(`home.how_to.${step}.items.${item}.text`))}</P>
            </Box>
          </HowToItemInnerItem>
        ))}
      </HowToItemInner>
    </HowToItemWrap>
  );
};

export default withTranslation('pages')(HowToItem);
