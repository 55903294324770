const DATA_AOS = 'data-aos' as const;
export type Aos = {
  'data-aos': string;
};

const makeAnimation = (animationType: string): Aos => ({
  [DATA_AOS]: animationType,
});

export const FADE_LEFT = makeAnimation('fade-left');
export const FADE_RIGHT = makeAnimation('fade-right');
export const FADE_UP = makeAnimation('fade-up');
