import React from 'react';
import { BANNER_LARGE, BANNER_SMALL } from '@sizeid/shared/types/banner';

import { ImageList } from 'components/ImageList';
import { FADE_UP } from 'utils/animations';
import { withTranslation } from 'lib/i18n';

import StatsInfo from '../Stats/StatsInfo';
import { Banner as BannerType } from '../types';

import { EshopsProps } from './types';
import Banner from './Banner';

const EshopsIndex: React.FC<EshopsProps> = props => {
  const largeBanners: { src: string; href: string }[] = [];
  const smallBanners: {
    id: number;
    logo: string | null;
    name: string;
    href: string;
  }[] = [];

  const addLargeBanner = (banner: BannerType): void => {
    largeBanners.push({
      src: banner.image ?? '',
      href: banner.link,
    });
  };
  const addSmallBanner = (banner: BannerType, index: number): void => {
    smallBanners.push({
      id: index,
      logo: banner.image,
      name: banner.name,
      href: banner.link,
    });
  };

  // eslint-disable-next-line no-unused-expressions
  props.banners?.forEach((banner, index) => {
    switch (banner?.type) {
      case BANNER_LARGE:
        addLargeBanner(banner);
        break;
      case BANNER_SMALL:
        addSmallBanner(banner, index);
        break;
      default:
        break;
    }
  });

  return (
    <>
      <div>
        <StatsInfo
          textId="EshopsStats"
          value={props.clientCount}
          text={props.t('home.eshops.involvedEshops')}
        />
        <Banner aos={FADE_UP} banners={largeBanners} />
        <ImageList aos={FADE_UP} list={smallBanners} />
      </div>
    </>
  );
};

export default withTranslation('pages')(EshopsIndex);
