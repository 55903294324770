import styled from 'styled-components';
import { key } from 'styled-theme';

type ImageProps = {
  active: boolean;
  relative: boolean;
};

const Image = styled.img<ImageProps>`
  position: ${props => (props.relative ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  transition: opacity 1s ease-in-out;
  z-index: ${(props: ImageProps) => (props.active ? 1000 : 0)};
  opacity: ${(props: ImageProps) => (props.active ? 1 : 0)};
  width: 100%;
  margin-bottom: 5px;
  box-shadow: ${key('outlines.shadow.default')};
  border-radius: ${key('sizes.borderRadius')};
`;

export default Image;
