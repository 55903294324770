import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;

  > div {
    width: 100%;
  }

  ${media.lessThan('sm')`
    display: block;

    h3,
    p {
      text-align: center;
    }

    svg {
      margin: 0 auto;
      width: 70px;
      height: 70px;
    }
  `};
`;
