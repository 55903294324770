import styled from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

export default styled.strong`
  color: ${key('text.headings.common.color')};
  font-weight: ${key('text.headings.common.fontWeight')};
  font-weight: bold;
  display: block;
  font-size: 36px;
  line-height: 50px;

  > svg {
    margin: -20px 20px -20px 0;
  }

  ${media.lessThan('sm')`
    font-size: 24px;
    line-height: 30px;

    > svg {
      display: block;
      margin: 0 auto 10px;
      width: 33px;
      height: 40px;
    }
  `};
`;
