import styled from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

export default styled.span`
  display: block;
  font-size: 72px;
  padding-bottom: 10px;
  font-weight: bold;
  color: ${key('colors.red.default')};

  ${media.lessThan('sm')`
    padding-bottom: 0;
  `};
`;
