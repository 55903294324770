import { css } from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

import WrapperContainer from 'components/Page/Wrapper/styled/WrapperContainer';

export const jumbotron = css`
  h1 {
    margin-top: 0;
  }

  ${media.moreThan('sm')`
    padding: 20px 0 40px 0;

    h1 {
      padding-top: 70px;
    }

    h1,
    p {
      text-align: left;
      margin-left: 0;
      margin-right: 0;
    }
  `};

  ${WrapperContainer} {
    ${media.moreThan('sm')`
      min-height: 400px;
    `};

    ${media.moreThan('lg')`
      min-height: 500px;
    `};
  }
`;

export const advisor = css`
  padding-top: 120px;
  padding-bottom: 0;
`;

export const features = css`
  h2 {
    padding-top: 50px !important;
    margin-bottom: 0;

    + p {
      margin-top: 1.88em;
    }
  }

  ${media.moreThan('md')`
    padding-top: 40px;
  `};

  ${WrapperContainer} {
    padding-bottom: 40px;

    ${media.moreThan('sm')`
      padding-bottom: 50px;
    `};
  }
`;
