import React from 'react';

import { FADE_RIGHT, FADE_LEFT } from 'utils/animations';

import HowToWrap from './styled/HowToWrap';
import HowToColumn from './styled/HowToColumn';
import HowToItem from './HowToItem';
import HowToArrow from './HowToArrow';

const HowTo: React.FC = () => (
  <HowToWrap>
    <HowToColumn>
      <HowToItem step="1" aos={FADE_RIGHT} />
      <HowToItem step="2" mobileOnly aos={FADE_LEFT} />
      <HowToItem step="3" aos={FADE_RIGHT} />
    </HowToColumn>

    <HowToColumn hideOnMobile>
      <HowToArrow aos={FADE_RIGHT} />
      <HowToItem step="2" aos={FADE_LEFT} />
      <HowToArrow left={true} aos={FADE_LEFT} />
    </HowToColumn>
  </HowToWrap>
);

export default HowTo;
