import React from 'react';

import RouteLink from 'components/RouteLink';

import List from './List';
import ListItem from './Item';
import { Link } from './styled';
import { ListProps, UniversalListProps } from './types';

const UniversalList: React.FC<UniversalListProps> = props => (
  <div>
    {props.children}
    <List>
      {props.list.map(item => (
        <ListItem
          {...props.aos}
          big={props.big}
          key={item.id}
          square={props.square}
        >
          {!item.internalLink ? (
            <Link href={item.href} target={item.target || '_blank'}>
              <img src={item.logo ?? undefined} alt={item.name} />
            </Link>
          ) : (
            <RouteLink href={{ pathname: item.href, query: item.params }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a target={item.target || '_self'}>
                <img
                  src={item.logo ?? undefined}
                  alt={item.name}
                  style={{ cursor: 'pointer' }}
                />
              </a>
            </RouteLink>
          )}
        </ListItem>
      ))}
    </List>
  </div>
);

export const ImageListSquare: React.FC<ListProps> = props => (
  <UniversalList {...props} square={true} />
);

export const ImageList: React.FC<ListProps> = props => (
  <UniversalList {...props} />
);
