import React, { useEffect } from 'react';
import AOS from 'aos';
import Button from '@sizeid/commons/components/Button';
import Box from '@sizeid/commons/components/Box';
import { nlBr } from '@sizeid/commons/utils/strings';
import { SIGN_INDEX } from '@sizeid/shared/constants/routes';
import { gql, useQuery } from '@apollo/client';

import { useTranslation } from 'lib/i18n';
import app from 'components/app';
import Page from 'components/Page';
import Section from 'components/Page/Section';
import Wrapper from 'components/Page/Wrapper';
import H1 from 'components/typo/Heading/H1';
import H2 from 'components/typo/Heading/H2';
import P from 'components/typo/P';
import RouteLink from 'components/RouteLink';
import BrandsTypeahead from 'containers/BrandsTypeahead';
import { HomeQuery } from 'types/graphql';

import HomeFeatures from './HomeFeatures';
import HowTo from './HowTo';
import Stats from './Stats';
import HomePeopleImage from './HomePeopleImage';
import Eshops from './Eshops';
import peopleImage from './images/image-oliver-and-anna-homepage.svg';
import { jumbotron, features } from './styles';

const HOME_QUERY = gql`
  query HomeQuery {
    popularBrands {
      model_id
      name
      logo
    }
    statistics {
      brandCount
      clientCount
    }
    banners {
      image
      type
      name
      link
    }
  }
`;

const Index: React.FC = () => {
  const { t } = useTranslation('pages');
  const { data } = useQuery<HomeQuery>(HOME_QUERY);
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
    });
  });

  return (
    <>
      <Page title={t('home.h1')} footer noPadding scrollingHeader>
        <>
          <Section css={jumbotron} headerColor>
            <Wrapper>
              <>
                <H1>{nlBr(t('home.h1'))}</H1>
                <P>{t('home.description')}</P>
                <BrandsTypeahead withAdvisor />
                <HomePeopleImage src={peopleImage} />
              </>
            </Wrapper>
          </Section>
          <Section css={features} white topWave>
            <Wrapper>
              <>
                <H2>{t('home.h2')}</H2>
                <HomeFeatures />
              </>
            </Wrapper>
          </Section>
          <Section gray bottomWave flipBottomWave topWave flipTopWave>
            <Wrapper>
              <>
                <HowTo />
                <Box paddingTop={1} align>
                  <RouteLink
                    href={{
                      pathname: SIGN_INDEX.route,
                      query: { singFormsTab: 1 },
                    }}
                    passHref
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <a>
                      <Button giant>{t('home.createProfile')}</Button>
                    </a>
                  </RouteLink>
                </Box>
              </>
            </Wrapper>
          </Section>
          <Section white>
            <Wrapper>
              <Stats
                popularBrands={data?.popularBrands || []}
                statistics={
                  data?.statistics || { brandCount: 0, clientCount: 0 }
                }
              />
            </Wrapper>
          </Section>
          <Section white>
            <Wrapper>
              <Eshops
                clientCount={data?.statistics?.clientCount || 0}
                banners={data?.banners || []}
              />
            </Wrapper>
          </Section>
        </>
      </Page>
    </>
  );
};

export default app(Index);
