import styled, { ThemedStyledProps } from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div<{ hideOnMobile?: boolean }>`
  display: flex;
  flex-direction: column;

  ${media.moreThan('lgPlus')`
    > div + div {
      margin-top: 90px;
    }
  `};

  ${media.lessThan('lg')`
    margin: 0 auto;

    > div + div {
      margin-top: 50px;
    }
  `};

  ${media.lessThan('sm')`
    display: ${(
      props: ThemedStyledProps<{ hideOnMobile?: boolean }, unknown>,
    ) => (props.hideOnMobile ? 'none' : 'block')};
    margin-top: -77px;

    > div + div {
      margin-top: 25px;
    }
  `};
`;
