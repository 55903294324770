import styled, { ThemedStyledProps } from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.ul<{ desktopOnly?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  ${media.lessThan('sm')`
    ${(props: ThemedStyledProps<{ desktopOnly?: boolean }, unknown>) =>
      props.desktopOnly ? 'display: none;' : ''}
  `};
`;
