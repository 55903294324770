import styled, {
  css,
  FlattenInterpolation,
  ThemedStyledProps,
} from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

import shape_1 from '../images/Homepage-shape-1.svg';
import shape_2 from '../images/Homepage-shape-2.svg';
import shape_3 from '../images/Homepage-shape-3.svg';

const shapes = {
  '1': {
    background: shape_1,
    margin: '50px',
  },
  '2': {
    background: shape_2,
    margin: '42px',
  },
  '3': {
    background: shape_3,
    margin: '35px',
  },
};

const styles = ({
  step,
}: {
  step: number;
}): FlattenInterpolation<ThemedStyledProps<unknown, unknown>> => css`
  background: url('${shapes[step.toString() as keyof typeof shapes]
      .background}')
    center center no-repeat;
  background-size: cover;
  margin-top: 30px;

  ${media.moreThan('sm')`
      background-size: contain;
    `};

  ${media.moreThan('md')`
      background: none;
      margin-top: ${shapes[step.toString() as keyof typeof shapes].margin};

      &:after {
        display: block;
        content: '';
        background: url('${
          shapes[step.toString() as keyof typeof shapes].background
        }') center center no-repeat;
        background-size: contain;
        position: absolute;
        top: -15%;
        left: -15%;
        bottom: -15%;
        right: -15%;
        z-index: 0;
      }
    `};
`;

export default styled.div`
  ${styles};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5%;
  position: relative;

  > div {
    margin-bottom: 15px;
  }
`;
