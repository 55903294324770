import React from 'react';

import { FADE_UP } from 'utils/animations';
import { ImageListSquare } from 'components/ImageList';
import { List } from 'components/ImageList/types';
import { withTranslation } from 'lib/i18n';

import { StatsProps } from '../types';

import StatsInfo from './StatsInfo';

const Stats: React.FC<StatsProps> = props => {
  const list: List =
    props.popularBrands?.map(brand => ({
      id: brand?.model_id ?? 0,
      name: brand?.name ?? '',
      logo: brand?.logo ?? '',
    })) ?? [];
  return (
    <ImageListSquare aos={FADE_UP} list={list}>
      <StatsInfo
        value={props.statistics.brandCount ?? 0}
        text={props.t('home.stats.brands.count')}
        textId="StatsInfo"
      />
    </ImageListSquare>
  );
};

export default withTranslation('pages')(Stats);
