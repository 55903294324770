import React from 'react';

import Features from 'components/Features';
import { FADE_UP } from 'utils/animations';

const KEYS = ['sizes', 'satisfaction', 'gift'];
const I18N_BASE = 'home.features';

const HomeFeatures: React.FC = () => (
  <Features aos={FADE_UP} keys={KEYS} base={I18N_BASE} />
);

export default HomeFeatures;
