import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  max-width: 1050px;
  margin: 35px auto 0 auto;

  ${media.lessThan('sm')`
    display: block;
    margin-bottom: 20px;
    max-width: 100%;
  `};

  ${media.moreThan('sm')`
    margin-top: 60px;
  `};

  ${media.moreThan('md')`
    margin-top: 0;
  `};
`;
