import React, { useState, useEffect } from 'react';
import Box from '@sizeid/commons/components/Box';

import Image from './styled/Image';
import { BannerProps } from './types';

const Banner: React.FC<BannerProps> = ({ aos, banners }) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  useEffect(() => {
    if (banners.length > 1) {
      const fadeOutTimer = setTimeout(() => {
        setActiveImageIndex((activeImageIndex + 1) % banners.length);
      }, 4000);
      return () => clearInterval(fadeOutTimer);
    }
  }, [banners, activeImageIndex]);

  return (
    <Box {...aos} width="100%">
      {banners.map((banner, index) => (
        <a href={banner.href} key={banner.src} target="_blank" rel="noreferrer">
          <Image
            active={index === activeImageIndex}
            relative={index === 0}
            src={banner.src}
          />
        </a>
      ))}
    </Box>
  );
};

export default Banner;
