import React, { RefObject, useRef, useState } from 'react';
import { Icon } from '@sizeid/commons/components/SvgImage';
import Button from '@sizeid/commons/components/Button';
import { gql, useQuery } from '@apollo/client';

import AdvisorTrigger from 'components/AdvisorTrigger';
import BrandsInput from 'components/BrandsInput';
import {
  ADVISOR_MODE_SELECT_SIZE_CHART,
  RECOMMENDATION_MODE_PROPERTIES,
} from 'components/AdvisorTrigger/types';
import { Brand } from 'types/brand';
import { withTranslation } from 'lib/i18n';
import { GetBrandsQuery } from 'types/graphql';

import BrandsTypeaheadContainer from './styled/BrandsTypeaheadContainer';
import { BrandsTypeaheadProps, BrandsTypeaheadState } from './types';

const GET_BRANDS_QUERY = gql`
  query GetBrandsQuery {
    brandsForRecommendation {
      id
      model_id
      name
    }
  }
`;

const BrandsTypeahead: React.FC<BrandsTypeaheadProps> = props => {
  const [state, setState] = useState<BrandsTypeaheadState>({ brand: null });
  const { data } = useQuery<GetBrandsQuery>(GET_BRANDS_QUERY);

  const advisorTrigger = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' && state.brand && advisorTrigger) {
      if (advisorTrigger.current) {
        advisorTrigger.current.click();
      }
    }
  };

  const handleSelectBrand = (brand: Brand | null | undefined): void => {
    setState({ brand });
  };

  return (
    <BrandsTypeaheadContainer withAdvisor={props.withAdvisor}>
      <BrandsInput
        brand={state.brand}
        brands={
          (data?.brandsForRecommendation?.filter(b => b) as Brand[]) || []
        }
        i18n={props.i18n}
        t={props.t}
        tReady={props.tReady}
        onBrandSelect={handleSelectBrand}
        onInputKeyDown={handleKeyDown}
      />
      {props.withAdvisor && (
        <AdvisorTrigger
          advisorMode={ADVISOR_MODE_SELECT_SIZE_CHART}
          recommendationMode={RECOMMENDATION_MODE_PROPERTIES}
          preselectBrand={
            state.brand ? state.brand.model_id.toString() : undefined
          }
          disabled={!state.brand}
          ref={advisorTrigger}
        >
          <Button giant disabled={!state.brand}>
            <span>{props.t('brandsTypeahead.advisor')}</span>
            <Icon image="search" />
          </Button>
        </AdvisorTrigger>
      )}
    </BrandsTypeaheadContainer>
  );
};

export default withTranslation('common')(BrandsTypeahead);
