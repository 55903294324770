import styled, { css } from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.img<{ left: boolean }>`
  display: block;
  width: 200px;
  margin-bottom: 60px;
  ${props =>
    props.left
      ? css`
          transform: rotate(150deg);
          margin-top: 50px;

          ${media.moreThan('lg')`
        margin-left: -40%;
    `};
        `
      : css`
          transform: rotate(30deg);
          margin-top: 109px;

          ${media.moreThan('lg')`
        margin-left: -50%;
    `};
        `};
`;
