import styled, { css } from 'styled-components';
import BoxContainer from '@sizeid/commons/components/Box/styled/BoxContainer';
import StyledButton from '@sizeid/commons/components/Button/styled/StyledButton';
import StyledButtonContent from '@sizeid/commons/components/Button/styled/StyledButtonContent';
import { media } from '@sizeid/commons/utils/styles';

import { BUTTON_CLASS } from 'components/AdvisorTrigger';

interface Props {
  withAdvisor?: boolean;
}

export default styled.div<Props>`
  width: 100%;
  margin: 20px auto 0 auto;
  text-align: left;
  display: flex;
  flex-flow: column;

  ${media.moreThan('md')`
    margin-top: 40px;
  `};

  > ${BoxContainer} {
    margin-bottom: 10px;
  }

  .${BUTTON_CLASS} {
    display: none;
  }

  .Select-placeholder,
  .Select-input,
  .Select-value,
  .Select-noresults {
    font-size: 18px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .Select-menu-outer {
    z-index: 99;
  }

  .Select-input > input {
    line-height: 54px;
    height: 54px;
    padding: 0;
    width: 100% !important;
  }

  .VirtualizedSelectOption {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 32px;
  }

  ${(props: Props) =>
    props.withAdvisor &&
    css`
      flex-flow: row;

      .${BUTTON_CLASS} {
        display: block;
      }

      ${BoxContainer} {
        .Select-control {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }

      ${StyledButton} {
        opacity: 1;
        box-shadow: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        ${StyledButtonContent} {
          white-space: pre;
        }
      }

      ${media.lessThan('sm')`
        ${BoxContainer} {
          flex-grow: 1;
          .Select-control {
            .Select-clear {
              width: 40px;
              font-size: 20px;
              margin-top: -5px;
            }
            > .Select-arrow-zone {
              display: none;
            }
          }
        }

        ${StyledButton} {
          padding: 14px 18px;
          margin: 0;
          ${StyledButtonContent} {
            padding: 0;
            > span {
              display: none;
            }
            > .icon {
              display: inline-block;
              margin: 0;
              font-size: 22px;
            }
          }
        }
      `};

      ${media.moreThan('sm')`
        margin-left: 0;
        width: 60%;

        ${BoxContainer} {
          flex: 0 0 65%;
          margin-bottom: 0;
        }

        ${StyledButton} ${StyledButtonContent} {
          > span {
            display: block;
          }
          > .icon {
            display: none;
          }
        }
      `};

      ${media.moreThan('md')`
        width: 50%;
        margin-bottom: 40px;
      `};

      ${media.moreThan('lg')`
        width: 40%;
        margin-bottom: 0;
      `};
    `};
`;
