import styled, {
  css,
  Interpolation,
  ThemedStyledProps,
} from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

const variant = ({
  square,
  big,
}: {
  square?: boolean;
  big?: boolean;
}): Interpolation<ThemedStyledProps<unknown, unknown>> => {
  if (square) {
    return css`
      flex: 0 0 ${big ? '250px' : '170px'};

      img {
        max-width: ${big ? '100%' : '100px'};
        max-height: ${big ? '100%' : '100px'};
      }

      ${media.lessThan('sm')`
        flex: 0 1 ${big ? '130px' : '93px'};
        height: ${big ? '130px' : '93px'};

        img {
        max-width: ${big ? '100%' : '60px'};
        max-height: ${big ? '100%' : '60px'};
        }
      `};
    `;
  }

  return css`
    ${media.lessThan('sm')`
      flex: 1 0 26%;
    `};
    ${media.moreThan('sm')`
      flex: 1 0 26%;
    `};
    img {
      max-height: 100%;
      max-width: 100%;
    }
  `;
};

export default styled.li`
  ${variant}
  display: flex;
  height: 170px;
  ${media.lessThan('sm')`
      height:100px
  `};
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: ${key('outlines.shadow.default')};
  border-radius: ${key('sizes.borderRadius')};
  margin: 4px;

  a {
    height: 100%;
    width: 100%;
  }

  img {
    display: block;
    margin: auto;
  }
`;
