import styled, { ThemedStyledProps } from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div<{ mobileOnly?: boolean; step: number }>`
  margin: 30px 0;

  h3 {
    margin: 0 0 10px;
    text-align: left;
  }

  p {
    margin: 0;
    text-align: left;
  }

  ${props => (props.mobileOnly ? 'display: none;' : '')};

  ${media.lessThan('sm')`
    ${(props: ThemedStyledProps<{ mobileOnly?: boolean }, unknown>) =>
      props.mobileOnly ? 'display: block;' : ''};
    margin: 0 -6%;
  `};

  ${media.moreThan('md')`
    max-width: 400px;
  `};
`;
