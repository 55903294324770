import React, { PureComponent } from 'react';
import Box from '@sizeid/commons/components/Box';
import Text from '@sizeid/commons/components/Text';
import StyledSelect from '@sizeid/commons/components/StyledSelect';
import { CONTACT_INDEX } from '@sizeid/shared/constants/routes';

import RouteLink from 'components/RouteLink';
import { Brand } from 'types/brand';

import { BrandsInputProps } from './types';

class BrandsInput extends PureComponent<BrandsInputProps> {
  public renderNoResults = (): JSX.Element => (
    <Box>
      {this.props.t('brandsTypeahead.missingBrand')}{' '}
      <Text bold primary>
        <RouteLink href={CONTACT_INDEX.page} passHref>
          <a href={CONTACT_INDEX.route}>
            {this.props.t('brandsTypeahead.addMissingBrand')}
          </a>
        </RouteLink>
      </Text>
    </Box>
  );

  public render(): JSX.Element {
    const { brand, brands, onBrandSelect, onInputKeyDown } = this.props;
    return (
      <>
        <Box>
          <StyledSelect
            options={brands ?? []}
            value={brand ?? undefined}
            labelKey="name"
            valueKey="model_id"
            isLoading={!brands}
            placeholder={this.props.t('brandsTypeahead.placeholder')}
            noResultsText={this.renderNoResults()}
            size="54px"
            autoFocus={true}
            openOnClick={false}
            openOnFocus={true}
            scrollMenuIntoView={false}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            onChange={brand => onBrandSelect(brand as Brand)}
            onInputKeyDown={onInputKeyDown}
            rounded
            handleOptionsShowing
          />
        </Box>
      </>
    );
  }
}

export default BrandsInput;
