import React, { useState } from 'react';
import { useCountUp } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import H2 from 'components/typo/Heading/H2';

import StatsInfoRedText from './styled/StatsInfoRedText';
import { StatsInfoProps } from './types';

const StatsInfo: React.FC<StatsInfoProps> = props => {
  const { countUp, start } = useCountUp({
    end: props.value,
    startOnMount: false,
  });

  const [started, setStarted] = useState(false);

  return (
    <H2>
      <VisibilitySensor
        onChange={(visible: boolean) => {
          if (visible && !started) {
            start();
            setStarted(true);
          }
        }}
        delayedCall
      >
        <StatsInfoRedText id={props.textId}>{countUp}</StatsInfoRedText>
      </VisibilitySensor>
      {props.text}
    </H2>
  );
};

export default StatsInfo;
