import React from 'react';

import { Aos } from 'utils/animations';

import StyledImage from './styled/StyledImage';
import arrow from './images/Homepage-arrow.svg';

const HowToArrow: React.FC<{ left?: boolean; aos?: Aos }> = props => {
  return (
    <div {...props.aos}>
      <StyledImage src={arrow} left={props.left ?? false} />
    </div>
  );
};

export default HowToArrow;
